
    import {
        useStore
    } from 'vuex'
    import {
        defineComponent,
        onMounted
    } from 'vue'

    export default defineComponent({
        setup() {
            const store = useStore();

            const setHeader = (() => {
                if (store.state.header) {
                    store.state.header = false
                } else {
                    store.state.header = true
                }

                console.log('输出状态：', store.state.header)
            })

            onMounted(() => {

                console.log('vueX', store.state.header)
            })

            return {
                store, // vuex 动态变量

                // 函数/方法
                setHeader
            }
        },
    })
