import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/home.vue'
import About from '@/views/about.vue'
import Blog from '@/views/blog.vue'
import Grid from '@/views/grid.vue'
import Masonry from '@/views/masonry.vue'
import SinglePost from '@/views/single-post.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/grid',
    name: 'Grid',
    component: Grid
  },
  {
    path: '/masonry',
    name: 'Masonry',
    component: Masonry
  },
  {
    path: '/single-post',
    name: 'SinglePost',
    component: SinglePost
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
