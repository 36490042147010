
  // $(function () {
  //   alert(123);
  // });
  import {
    defineComponent,
    onMounted,
    ref
  } from 'vue';
  import {
    notification
  } from 'ant-design-vue';
  import Icon from '@ant-design/icons-vue';
  import {
    LeftCircleOutlined,
    RightCircleOutlined
  } from '@ant-design/icons-vue';

  export default defineComponent({
    components: {
      Icon
    },
    name: 'Home',
    setup() {
      const preview = ref(false)
      const previewUrl = ref('')
      const homeList = ref([{
          title: 'Biodiesel',
          text: 'Awesome Subtittle Goes Here',
          em: 'squid',
          url: require('@/assets/img/portfolio_item_4.png')
        },
        {
          title: 'raclette',
          text: 'Awesome Subtittle Goes Here',
          em: 'taxidermy',
          url: require('@/assets/img/portfolio_item_2.png')
        },
        {
          title: 'humblebrag',
          text: 'Awesome Subtittle Goes Here',
          em: 'brunch',
          url: require('@/assets/img/portfolio_item_3.png')
        },
        {
          title: 'Succulents',
          text: 'Awesome Subtittle Goes Here',
          em: 'chambray',
          url: require('@/assets/img/portfolio_item_1.png')
        },
        {
          title: 'freegan',
          text: 'Awesome Subtittle Goes Here',
          em: 'aesthetic',
          url: require('@/assets/img/portfolio_item_5.png')
        },
        {
          title: 'taiyaki',
          text: 'Awesome Subtittle Goes Here',
          em: 'vegan',
          url: require('@/assets/img/portfolio_item_6.png')
        },
        {
          title: 'Thundercats',
          text: 'Awesome Subtittle Goes Here',
          em: 'santo',
          url: require('@/assets/img/portfolio_item_7.png')
        },
        {
          title: 'wayfarers',
          text: 'Awesome Subtittle Goes Here',
          em: 'yuccie',
          url: require('@/assets/img/portfolio_item_8.png')
        },
        {
          title: 'disrupt',
          text: 'Awesome Subtittle Goes Here',
          em: 'street',
          url: require('@/assets/img/portfolio_item_9.png')
        }
      ])

      const setPreview = (state: any, url: any) => {
        preview.value = state
        if (state) {
          previewUrl.value = '@/assets' + url
          console.log('点击内容：', previewUrl.value)
        }
      }

      /**
       * 表格点击页码时触发
       */
      const show = () => {
        notification.open({
          message: '提示',
          description: '敬请期待...',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      };

      return {
        preview,
        previewUrl,
        homeList,

        // 方法变量
        show,
        setPreview
      }
    }
  });
