
  import {
    defineComponent
  } from "vue";
  import TheHeader from "@/components/the-header.vue";
  import TheNavbar from "@/components/the-navbar.vue";
  import TheFooter from "@/components/the-footer.vue"
  export default defineComponent({
    name: "app",
    components: {
      TheHeader,
      TheFooter,
      TheNavbar
    },
  });
