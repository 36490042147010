
  import {
    defineComponent
  } from "vue";

  export default defineComponent({
    name: 'the-footer',
    setup() {
      return {

      }
    }
  })
