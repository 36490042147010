
  import {
    useStore
  } from 'vuex'
  import {
    defineComponent,
    ref
  } from "vue";

  export default defineComponent({
    name: "the-header",
    setup() {
      const store = useStore();
      const upList = ref([{
        title: '首页',
        path: '/'
      }, {
        title: '产品介绍',
        path: '/about'
      }, {
        title: '客户案例',
        path: '/blog'
      }, {
        title: '服务帮助',
        path: '/grid'
      }, {
        title: '渠道合作',
        path: '/masonry'
      }, {
        title: '关于我们',
        path: '/single-post'
      }])

      const setHeader = (() => {
        if (store.state.header) {
          store.state.header = false
        } else {
          store.state.header = true
        }

        console.log('输出状态：', store.state.header)
      })

      return {
        store, // vuex
        upList,

        setHeader
      }
    }

  });
