import { createStore } from 'vuex'


export default createStore({
  state: {
    header: false
  },
  mutations: {
    setHeader(state, status) {
      state.header = status
      // console.log('vuex', state.header)
    }
  },
  actions: {
  },
  modules: {
  }
})
